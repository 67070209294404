export const vodCollectionPermissions = {
  VOD_VIEW: {
    enabled: ['vod_view', 'vod_edit', 'vod_create'],
  },
  VOD_CREATE: {
    enabled: ['vod_create'],
  },
  VOD_EDIT: {
    enabled: ['vod_edit'],
    disabled: ['vod_view', 'vod_create'],
  },
  VOD_CAPSTAN: {
    enabled: ['amlg_active'],
    disabled: ['vod_view', 'vod_create', 'vod_edit'],
  },
};
