import {RuleGroupType} from '../models/queryBuilder';

export const formatQuery = (query: RuleGroupType, format: 'json' | 'capstan'): string => {
  if (format === 'json') {
    return query.rules.length === 0 ? '' : JSON.stringify(query, null, 2);
  }

  // Add logic for other formats if needed
  return '';
};
