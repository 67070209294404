import {IVodCategoryTitle} from 'models/vodCategoryEntry';
import {rewriteImageUrl} from 'utils/imageUtils';

export const vodEntryMapper = (vodEntries: any[] = []): IVodCategoryTitle[] => {
  const titles: IVodCategoryTitle[] = vodEntries.map(entry => {
    const content = entry.series || entry.episode;
    const vodEntries = content.vod_category_entries;
    const isEpisode = !!entry.episode;
    const isSeries = !!entry.series;
    const author = entry.author || content.author || '';

    const availWin = content?.availability_windows?._a_v_o_d ?? content?.availabilityWindows?.AVOD;

    const availabilityWindows = availWin?.map((window: any) => ({
      startDate: window.start_date ?? window.startDate,
      endDate: window.end_date ?? window.endDate,
      id: window.id,
    }));

    const formatDictionary = {
      film: 'Film',
      tv: 'TV',
      // ideally these types would not appear at program tab
      'web-original': 'Web Original',
      'music-video': 'Music Video',
      live: 'Live',
    };

    // Series default to featured_image
    // Films default to poster16_9 and fallback to vertical poster
    const poster16_9 = isSeries
      ? rewriteImageUrl(content?.featured_image?.path)
      : rewriteImageUrl(content?.poster16_9?.path) || rewriteImageUrl(content?.poster?.path);

    return {
      id: entry.id,
      order: entry.order,
      duration: content.duration,
      ageRange: content.age_range ? content.age_range[0].display_name : null,
      state: 'none',
      thumbnail: rewriteImageUrl(content?.poster?.path) || '',
      name: content?.name || '',
      displayName: content?.displayName || '',
      collections: content?.vod_category_entries_count,
      seriesType: formatDictionary[isEpisode ? 'film' : content?.type] || 'Film',
      genre: content?.genre || '',
      category: content?.category || '',
      subCategory: content?.sub_category || '',
      subGenre: content?.sub_genre || '',
      vodEntryId: entry.id,
      seriesCount: isSeries ? entry?.series?.season_count?.toString() || '0' : 'N/A',
      episodeCount: isSeries ? entry?.series?.episode_count?.toString() || '0' : 'N/A',
      poster16_9: poster16_9 || '',
      vodCatEntries: vodEntries || [],
      availabilityWindows,
      contentId: content.id,
      contentType: isSeries ? 'series' : 'episode',
      published: content?.published,
      ad_pods: content?.ad_pods,
      all_clips_ready: content?.all_clips_ready,
      has_unique_provider: content?.has_unique_provider,
      ad_pods_verified: content?.ad_pods_verified,
      ad_pods_duration: content?.ad_pods_duration,
      sources: isEpisode ? content?.sources : undefined,
      episodeSeriesId: entry?.episode?.series?.id,
      author: author,
    } as IVodCategoryTitle;
  });

  return titles;
};
